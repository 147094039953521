<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="集污点检索" :label-col="labelCol" :wrapper-col="wrapperCol" v-if="phoneType==='repair'">
        <a-auto-complete style="width: 100%"
                         v-model="searchContent"
                         autoFocus
                         @select="onSelectSpotItem"
                         :dropdown-match-select-width="false"
                         :dropdown-style="{ width: '700px' }"
                         option-label-prop="label"
                         placeholder="请输入集污点关键字">
          <template slot="dataSource">
            <a-select-option v-for="item in keySearchData" :key="item.no" :value="item.id+''" :label="item.name">
              <a-tag v-if="item.type===spotTypeEnum.DANWENG.value" color="#00BFFF">
                单翁
              </a-tag>
              <a-tag v-if="item.type===spotTypeEnum.JIWUCHI.value" color="#00CD66">
                集污池
              </a-tag>
              <a-tag v-if="item.type===spotTypeEnum.SANGE.value" color="#00CED1">
                三格化粪池
              </a-tag>
              {{item.no}}
              <a-divider type="vertical"/>
              {{item.name}}
              <a-divider type="vertical"/>
              {{item.areaName}}
              <a-divider type="vertical"/>
              {{item.houseNo?item.houseNo:'~'}}
              <a-divider type="vertical"/>
              <a-icon type="phone"/>&nbsp;{{item.userPhone}}
            </a-select-option>
          </template>
          <a-input>
            <a-icon slot="suffix" type="search" @click="searchClick"/>
            <a-divider slot="suffix" type="vertical"/>
            <a-icon slot="suffix" type="sync" @click.stop="restForm"/>
          </a-input>
        </a-auto-complete>
      </a-form-item>
      <a-form-item label="时间"
                   :label-col="labelCol"
                   :wrapper-col="wrapperCol">
        <a-date-picker style="width: 100%"
                       format="YYYY-MM-DD"
                       v-decorator="['reportTime',{initialValue: moment(),rules: [{ required: true, message: '上报时间不能为空' }]}]"
                       placeholder="请选择"></a-date-picker>
      </a-form-item>
      <a-form-item label="姓名" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['userName' ]" placeholder="请输入姓名"></a-input>
      </a-form-item>
      <a-form-item label="电话" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['phone' ]" placeholder="请输入电话"></a-input>
      </a-form-item>
      <a-form-item label="区域" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-cascader style="width: 230px" :options="areaOptions"
                    :defaultValue="areaDefaultValue"
                    v-model="areaDefaultValue"
                    placeholder="选择区域" @change="areaOnChange">
        </a-cascader>
        <a-input style="width: 150px;margin-left: 10px" v-decorator="['location' ]" placeholder="请输入详细位置"></a-input>
      </a-form-item>
      <a-form-item label="上报原因" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea v-decorator="['reportReason', { rules: [{ required: true, message: '上报原因不能为空' }] }]" rows="3" :maxLength="200" placeholder="请输入上报原因，最多输入200字"></a-textarea>
      </a-form-item>
      <a-form-item label="图片" :label-col="labelCol" :wrapper-col="wrapperCol">
        <qiniu-image-upload
          ref="qiniuImageRef"
          @getImageData="getImageData"
          :multiSelection="true"
          type="edit"
          :items="formItem.images"
        ></qiniu-image-upload>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { phoneReportVO } from './common/FormVO'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  import entityCURDCallback from '../../common/mixins/entityCURDCallback'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'

  export default {
    name: 'Form',
    mixins: [entityCURDCallback],
    components: { QiniuImageUpload },
    props: {
      type: {
        type: String,
        default: 'edit'
      },
      phoneType: {
        type: String,
        required: true
      }
    },
    watch: {
      phoneType (ele) {
        this.phoneType = ele
      }
    },
    mounted () {
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.phoneReportApi,
        initFormItem: phoneReportVO(),
        showFormItem: false,
        spinShow: false,
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        keySearchData: [],
        searchContent: '',
        spotTypeEnum: {
          'DANWENG': {
            value: 'DANWENG',
            description: '单翁'
          },
          'SANGE': {
            value: 'SANGE',
            description: '三格化粪池'
          },
          'JIWUCHI': {
            value: 'JIWUCHI',
            description: '集污池'
          },
          'LANI': {
            value: 'LANI',
            description: '拉泥'
          },
          'GONGCE': {
            value: 'GONGCE',
            description: '公厕'
          },
          'OTHER': {
            value: 'OTHER',
            description: '其他'
          }
        },
        areaOptions: [],
        areaDefaultValue: []
      }
    },
    created () {
      this.getCascadeData()
    },
    methods: {
      moment,
      getCascadeData () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.areaDictionaryApi.cascade,
          noTips: true,
          success: (data) => {
            this.areaOptions = data.body
          }
        })
      },
      setFields (values) {
        Object.assign(this.formItem, values.phoneReportInfo)
        this.formItem.spotId = this.formItem.spot ? this.formItem.spot.id : ''
        this.formItem.areaId = this.formItem.areaDictionary ? this.formItem.areaDictionary.id : ''
        this.form.setFieldsValue({
          reportTime: moment(this.formItem.reportTime),
          location: this.formItem.location,
          phone: this.formItem.phone,
          reportReason: this.formItem.reportReason,
          userName: this.formItem.userName
        })
        this.formItem.images = values.phone_report_image
        if (this.formItem.areaDictionary) {
          var areaDefault = []
          areaDefault.push(this.formItem.areaDictionary.code.substring(0, 2) + '0000')
          areaDefault.push(this.formItem.areaDictionary.code.substring(0, 4) + '00')
          areaDefault.push(this.formItem.areaDictionary.code.substring(0, 6))
          this.areaDefaultValue = areaDefault
        } else {
          this.areaDefaultValue = []
        }

      },
      setVOFields (values) {
        Object.assign(this.formItem, values)
        this.formItem.phoneType = this.phoneType
        this.formItem.userName = values.userName
        this.formItem.phone = values.phone
        this.formItem.repairReason = values.repairReason
        this.formItem.reportTime = values.reportTime
      },
      onSelectSpotItem (id) {
        for (let i = 0; i < this.keySearchData.length; i++) {
          const item = this.keySearchData[i]
          if (item.id === Number(id)) {
            this.setReportFields(item)
          }
        }
      },
      setReportFields (value) {
        console.log(value)
        this.formItem.spotId = value.id
        var areaDefault = []
        areaDefault.push(value.areaNo.substring(0, 2) + '0000')
        areaDefault.push(value.areaNo.substring(0, 4) + '00')
        areaDefault.push(value.areaNo.substring(0, 6))
        this.areaDefaultValue = areaDefault
        this.form.setFieldsValue({
          location: value.areaName + value.houseNo,
          userName: value.name,
          phone: value.userPhone
        })
        this.setAreaName(this.areaOptions, value.areaNo)
      },
      searchClick () {
        if (this.searchContent.length > 1) {
          this.searchGatherSpotByKey(this.searchContent)
        } else {
          this.keySearchData = []
          this.$message.warning('关键字过少！')
        }
      },
      searchGatherSpotByKey (key) {
        this.$http(this, {
          url: SERVICE_URLS.csgz.gatherSpot.findOneByKeyLike,
          data: { 'key': key, 'ifDataAuth': true },
          noTips: true,
          loading: 'spinShow',
          success: (data) => {
            this.$nextTick(() => {
              if (data.body.length) {
                this.keySearchData = data.body
              }
            })
          }, error: (data) => {
            this.keySearchData = []
          }
        })
      },
      restForm () {
        this.areaDefaultValue = []
        this.form.resetFields()
      },
      /*区域选择处理*/
      areaOnChange (options, selectedOptions) {
        console.log(options, selectedOptions)
        this.formItem.areaId = selectedOptions[2].id
      },

      /*区域名称递归设置*/
      setAreaName (arr, key) {
        for (var i in arr) {
          if (arr[i].value === key) {
            this.formItem.areaId = arr[i].id
            break
          }
          if (arr[i].children) {
            this.setAreaName(arr[i].children, key)
          }
        }
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.formItem.imageIds = []
        data.forEach(item => {
          this.formItem.imageIds.push(item.id)
        })
      }
    }
  }
</script>
