<template>
  <a-spin :spinning="spinShow">
    <a-form-model :model="phoneReportInfo">
      <a-card :headStyle="{fontWeight:'bold'}" title="来电事由" :bordered="false">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="日期" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-date-picker class="custom-disabled" :disabled="true" style="width: 100%"
                             v-model="phoneReportInfo.reportTime" placeholder="" format="YYYY-MM-DD"></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="姓名" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input class="custom-disabled" :disabled="true" v-model="phoneReportInfo.userName"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="电话" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input class="custom-disabled" :disabled="true" v-model="phoneReportInfo.phone"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="地址" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input class="custom-disabled" :disabled="true" v-model="phoneReportInfo.location"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="来电事由" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-textarea class="custom-disabled" :disabled="true" v-model="phoneReportInfo.reportReason" rows="1"></a-textarea>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8" v-if="phoneReportInfo.reportImage.length>0">
            <a-form-model-item label="图片" :label-col="labelCol" :wrapper-col="wrapperCol">
              <qiniu-image-upload
                class="display-image-edit"
                ref="qiniuImageRef"
                :multiSelection="true"
                type="edit"
                :items="phoneReportInfo.reportImage"
              ></qiniu-image-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>
      <a-card :headStyle="{fontWeight:'bold'}" style="margin-top: 10px" title="维修方法" :bordered="false">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-model-item label="日期" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-date-picker class="custom-disabled" :disabled="true" style="width: 100%"
                             v-model="phoneReportInfo.disposeTime" format="YYYY-MM-DD"
                             placeholder=""></a-date-picker>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="姓名" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input class="custom-disabled" :disabled="true" v-model="phoneReportInfo.disposeName"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="维修方法" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-textarea class="custom-disabled" :disabled="true" v-model="phoneReportInfo.disposeResult" rows="1"></a-textarea>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row v-if="phoneReportInfo.disposeImages.length>0">
          <a-col :span="8">
            <a-form-model-item label="图片" :label-col="labelCol" :wrapper-col="wrapperCol">
              <qiniu-image-upload
                class="display-image-edit"
                ref="qiniuImageRef"
                :multiSelection="true"
                type="edit"
                :items="phoneReportInfo.disposeImages"
              ></qiniu-image-upload>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-card>
    </a-form-model>
    <a-card :headStyle="{fontWeight:'bold'}" title="回访" style="margin-top: 10px" :bordered="false">
      <a-form :form="form">
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item label="回访日期" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-date-picker style="width: 100%"
                             format="YYYY-MM-DD"
                             v-decorator="['time',{initialValue: moment(),rules: [{ required: true, message: '回访日期不能为空' }]}]"
                             placeholder="请选择"></a-date-picker>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="回访人" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input v-decorator="['name' ]" placeholder="请输入姓名"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="回访结果" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-textarea v-decorator="['reason', { rules: [{ required: true, message: '回访结果不能为空' }] }]" rows="1" :maxLength="200" placeholder="请输入上报原因，最多输入200字"></a-textarea>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item label="图片" :label-col="{span:'2'}" :wrapper-col="wrapperCol" style="margin-left: -2px;">
              <qiniu-image-upload
                ref="qiniuImageRef"
                @getImageData="getImageData"
                :multiSelection="true"
                type="edit"
                :items="formItem.images"
              ></qiniu-image-upload>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-card>
  </a-spin>
</template>

<script>
  import { callBackVO } from './common/FormVO'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'

  export default {
    name: 'CheckedForm',
    mixins: [],
    props: {},
    components: { QiniuImageUpload },
    mounted () {
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.csgz.repairRecordApi,
        form: this.$form.createForm(this),
        formItem: callBackVO(),
        showFormItem: false,
        spinShow: false,
        labelCol: { span: 6 },
        wrapperCol: { span: 17 },
        phoneReportInfo: {
          reportTime: '',
          disposeTime: '',
          reportImage: [],
          disposeImages: []
        }
      }
    },
    computed: {},
    created () {
    },
    methods: {
      moment,
      loadData (id) {
        this.formItem.id = id
        this.$http(this, {
          url: SERVICE_URLS.csgz.phoneReportApi.view,
          params: { id },
          noTips: true,
          success: (data) => {
            this.phoneReportInfo = data.body.phoneReportInfo
            this.phoneReportInfo.reportTime = this.phoneReportInfo.reportTime ? moment(this.phoneReportInfo.reportTime) : ''
            this.phoneReportInfo.disposeTime = this.phoneReportInfo.disposeTime ? moment(this.phoneReportInfo.disposeTime) : ''
            this.setFields(data.body)
          }
        })
      },
      setFields (values) {
        this.phoneReportInfo.reportImage = values.phone_report_image
        this.phoneReportInfo.disposeImages = values.phone_report_processed_image
        this.formItem.images = values.phone_report_repeated_image
        this.form.setFieldsValue({
          time: this.phoneReportInfo.callBackTime ? moment(this.phoneReportInfo.callBackTime) : moment(),
          name: this.phoneReportInfo.callBackName,
          reason: this.phoneReportInfo.callBackResult
        })
      },
      handCallBack () {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.csgz.phoneReportApi.phone_report_dispose_and_callback,
                data: this.formItem,
                params: {
                  id: this.formItem.id
                },
                noTips: true,
                success: (data) => {
                  //清空表单数据
                  this.formItem = callBackVO()
                  this.form.resetFields()
                  this.$emit('callBackSuccess', data.body)
                }
              })
            }
          }
        )
      },
      setVOFields (values) {
        Object.assign(this.formItem, values)
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.formItem.imageIds = []
        data.forEach(item => {
          this.formItem.imageIds.push(item.id)
        })
      }
    }
  }
</script>
